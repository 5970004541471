<template>
  <page-manage-base>
    <template v-slot:title>{{ $route.name }}</template>
    <template v-slot:default>
      <v-card class="mx-10 mt-5" shaped tile>
        <Table
          :total="getTotalWebRequestList"
          :headers="headers"
          :items="webrequestlistFacility"
          :attr="{
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id',
            class: 'elevation-1 custom-table mt-n4',
          }"
          ref="table"
          :itemsPerPage="10"
          :funReset="loadList"
          :sortField="[]"
          :filter="filter"
          :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
          :multiSort="true"
        >
          <template v-slot:top>
            <v-row>
              <v-col :cols="10">
                <v-text-field
                  @change="searchData"
                  append-icon="mdi-magnify"
                  label="検索"
                  class="ml-8"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col :cols="2">
                <v-checkbox
                  class="ml-16"
                  v-model="getHandledFacility"
                  :label="
                    $t(
                      'webPageManage.VisitReservation.AlsoDisplayedAsSupported',
                    )
                  "
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.handledAt`]="{ item }">
            <v-chip
              :color="item.handledAt !== null ? '#616161' : '#DB3069'"
              class="status"
              label
              text-color="white"
            >
              {{ item.handledAt !== null ? '対応済' : '未対応' }}
            </v-chip>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <div v-if="item.createdAt !== null">
              {{ item.createdAt.substr(5, 2) }}/{{
                item.createdAt.substr(8, 2)
              }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div v-if="item.name !== null">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:[`item.tel`]="{ item }">
            <div v-if="item.tel !== null">
              {{ item.tel }}
            </div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <div v-if="item.email !== null">
              {{ item.email }}
            </div>
          </template>
          <template v-slot:[`item.firstDate`]="{ item }">
            <div class="d-flex justify-content-between">
              <div style="min-width: 40px">
                {{ item.firstCheckInDate && formatDate(item.firstCheckInDate) }}
              </div>
              <div style="min-width: 40px">
                <span v-if="item.firstStayDays !== null" class="mx-2">
                  {{ item.firstStayDays }} 泊
                </span>
              </div>
              <div style="min-width: 40px">
                {{ item.firstFacility && item.firstFacility.name }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.secondDate`]="{ item }">
            <div class="d-flex justify-content-between">
              <div style="min-width: 40px">
                {{ item.secondCheckInDate && formatDate(item.secondCheckInDate) }}
              </div>
              <div style="min-width: 40px">
                <span v-if="item.secondStayDays !== null" class="mx-2">
                  {{ item.secondStayDays }} 泊
                </span>
              </div>
              <div style="min-width: 40px">
                {{ item.secondFacility && item.secondFacility.name }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn
              v-if="item.clientId !== null"
              class="t-btn--prm"
              icon
              target="_blank"
              :to="
                checkPerUser
                  ? '/customer-details/' +
                    item.clientId +
                    '?typeClient=' +
                    item.client.type
                  : ''
              "
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.times`]="{ item }">
            <span v-if="item.times === null">
              空白
            </span>
            <span v-if="item.times === 0">
              初回
            </span>
            <span v-if="item.times > 0"> {{ item.times + 1 }} 回目 </span>
          </template>

          <template v-slot:[`item.clientId`]="{ item }">
            <div class="d-flex justify-start">
              <span v-if="item.clientId === null">なし</span>
              <span v-if="item.clientId !== null && item.wasExistingClient"
                >紐づけ済み</span
              >
              <span v-if="item.clientId !== null && !item.wasExistingClient"
                >作成済み</span
              >
            </div>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              :disabled="!checkPerUser"
              class="btn-crm-primary"
              style="border: 0px"
              icon
            >
              <v-icon
                @click="
                  checkPerUser
                    ? ((visible = 1), getWebReqEditFacility(parseInt(item.id)))
                    : ''
                "
                x-large
                >mdi-pencil-circle</v-icon
              >
            </v-btn>
          </template>
        </Table>
        <FacilityListDetail
          :visible="visible === 1"
          v-if="checkPerUser"
          @reset="$refs.table.reset()"
          @close="visible = -1"
        ></FacilityListDetail>
      </v-card>
    </template>
  </page-manage-base>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PageManageBase from '../PageManageBase.vue';
import FacilityListDetail from './FacilityListDetail.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import moment from 'moment';
import Table from '@/components/Table/index.vue';

export default {
  name: 'NoticeList',
  data() {
    return {
      filter: {
        keyword: '',
        handled: false,
      },
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      itemId: null,
      headers: [
        {
          text: this.$t('webPageManage.experience.status'),
          fieldName: 'webRequest.isHandled',
          value: 'handledAt',
          align: 'center',
        },
        {
          text: this.$t('webPageManage.experience.date'),
          fieldName: 'webRequest.createdAt',
          value: 'createdAt',
        },
        {
          text: this.$t('webPageManage.experience.name'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('webPageManage.experience.telephoneNumber'),
          fieldName: 'webRequest.tel',
          value: 'tel',
        },
        {
          text: this.$t('webPageManage.experience.Email'),
          fieldName: 'webRequest.email',
          value: 'email',
        },
        {
          text: this.$t('webPageManage.experience.FirstChoiceDate'),
          fieldName: 'webRequest.firstCheckInDate',
          value: 'firstDate',
        },
        {
          text: this.$t('webPageManage.experience.secondChoiceDate'),
          fieldName: 'webRequest.secondCheckInDate',
          value: 'secondDate',
        },
        {
          text: '問い合わせ詳細',
          sortable: false,
          value: 'edit',
          align: 'center',
        },
        {
          text: this.$t('webPageManage.experience.client'),
          sortable: false,
          value: 'clientId',
        },
        {
          text: this.$t('webPageManage.experience.CustomerDetails'),
          sortable: false,
          value: 'detail',
          align: 'center',
        },
        {
          text: '回数',
          sortable: false,
          value: 'times',
          align: 'center',
        },
      ],
    };
  },

  mounted() {
    if (this.$refs.table) {
      this.$refs.table.reset();
    }
  },

  computed: {
    ...mapGetters([
      'getTotalWebRequestList',
      'webrequestlistFacility',
      'keywordFacility',
      'handledFacility',
    ]),
    getHandledFacility: {
      get() {
        return this.handledFacility;
      },
      set(value) {
        // this.setHandledFacility(value);
        // this.getWebrequestlistFacility(this.orderBy);
        this.filter.handled = value;
        this.$refs.table.resetPageAndSearch();
      },
    },
  },
  methods: {
    ...mapActions(['getWebrequestlistFacility', 'getWebReqEditFacility']),
    ...mapMutations([
      'setWebRequestListTake',
      'setWebRequestListSkip',
      'setKeywordFacility',
      'setHandledFacility',
    ]),
    formatDate(date) {
      return moment(date).format('MM/DD');
    },
    async searchData(v) {
      this.filter.keyword = v;
      this.$refs.table.resetPageAndSearch();
    },

    async loadList(variables) {
      if (variables) {
        await this.getWebrequestlistFacility(variables);
      }
    },
  },
  components: {
    PageManageBase,
    FacilityListDetail,
    Table,
  },
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep {
  .v-data-footer__pagination {
    display: none;
  }
}
::v-deep {
  .v-data-table__wrapper {
    .v-data-table-header {
      tr {
        th {
          font-size: 14px !important;
          color: #424242 !important;
          font-weight: 400 !important;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 14px !important;
          color: #424242 !important;
          font-weight: 400 !important;
        }
      }
    }
  }
}
</style>
