<template>
  <div>
    <v-dialog v-model="dialog">
      <v-form ref="form">
      <div class="d-flex justify-space-between">
        <h2 class="text-title">
          {{ $t('webPageManage.experience.AccommodationExperience') }}
        </h2>
        <h4 class="text-time">
          受信日:

          <span class="ml-4" v-if="webReqEditFacility.createdAt">
            {{ formatDate(webReqEditFacility.createdAt) }}
          </span>
        </h4>
      </div>

      <!-- select -->
      <div class="d-inline-flex">
        <p class="mt-5 icon-alert">
          {{ $t('webPageManage.experience.status') }}
        </p>
        <v-col>
          <v-select
            style="width: 140px; color: #666666"
            v-model="status"
            :items="[
                { id: 'Handled', name: '対応済' },
                { id: 'New', name: '未対応' },
              ]"
              item-text="name"
              item-value="id"
            outlined
          ></v-select>
        </v-col>
      </div>

      <!-- end select -->
      <div class="d-flex align-center">
        <div
          v-if="webReqEditFacility.clientId === null"
          class="d-flex justify-space-between div-alert mr-2"
        >
          <div>
            <i
              class="fas fa-exclamation-triangle mt-2"
              style="font-size: 22px; color: white"
            ></i
            ><span class="mt-2 ml-3 mr-2" style="font-size: 16px">{{
              $t('webPageManage.experience.NoCustomers')
            }}</span>
          </div>
          <div>
            <v-btn class="mr-2 btn-div-alert" @click="visible = 2">
              {{ $t('webPageManage.experience.ConnectWithExistingCustomers') }}
            </v-btn>
            <v-btn class="btn-div-alert">
              {{ $t('webPageManage.experience.CreateANewCustomer') }}
            </v-btn>
          </div>
        </div>
        <div v-else>
          <v-btn class="btn-customer-details" 
          target="_blank"
          :to="'/customer-details/' +
                  webReqEditFacility.clientId +
                  '?typeClient=' +
                  webReqEditFacility.client.type 
              ">顧客詳細</v-btn>
        </div>
      </div>
      <!-- content -->
      <v-row class="mt-1">
        <v-col cols="6">
          <v-card class="pa-5">
            <h2 class="text-title">
              {{ $t('webPageManage.experience.CustomerInformation') }}
            </h2>
            <v-divider class="mt-5"></v-divider>
            <div class="content">
              <div>
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span class="text-12px">{{
                    $t('webPageManage.experience.Name')
                  }}</span>
                  <span class="mr-5">
                    {{
                      webReqEditFacility.lastName
                        ? webReqEditFacility.lastName
                        : ''
                    }}
                    {{
                      webReqEditFacility.firstName
                        ? webReqEditFacility.firstName
                        : ''
                    }}
                  </span>
                </div>
              </div>
              <div>
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span>{{ $t('webPageManage.experience.Furigana') }}</span>
                  <span class="mr-5">
                    {{
                      webReqEditFacility.lastKana
                        ? webReqEditFacility.lastKana
                        : ''
                    }}
                    {{
                      webReqEditFacility.firstKana
                        ? webReqEditFacility.firstKana
                        : ''
                    }}
                  </span>
                </div>
              </div>
              <div>
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span>{{ $t('webPageManage.experience.Birthday') }}</span>
                  <span class="mr-5">{{
                    webReqEditFacility.birthday
                      ? webReqEditFacility.birthday
                      : ''
                  }}</span>
                </div>
              </div>
              <div>
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span>{{ $t('webPageManage.experience.PostalCode') }}</span>
                  <span class="mr-5">{{
                    webReqEditFacility.postalCode
                      ? webReqEditFacility.postalCode
                      : ''
                  }}</span>
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div style="margin: auto; font-size: 10px">
                  <span>{{ $t('webPageManage.experience.Address') }}</span>
                </div>
                <div style="width: 560px">
                  <div class="boder_gray_padding d-flex justify-space-between">
                    <span
                      >({{ $t('webPageManage.experience.Prefectures') }})</span
                    >
                    <span class="mr-5">{{
                      webReqEditFacility.prefecture
                        ? webReqEditFacility.prefecture
                        : ''
                    }}</span>
                  </div>
                  <div class="boder_gray_padding d-flex justify-space-between">
                    <span
                      >({{ $t('webPageManage.experience.CityOrCounty') }})</span
                    >
                    <span class="mr-5">{{
                      webReqEditFacility.locality
                        ? webReqEditFacility.locality
                        : ''
                    }}</span>
                  </div>
                  <div class="boder_gray_padding d-flex justify-space-between">
                    <span
                      >({{ $t('webPageManage.experience.TownName') }}・{{
                        $t('webPageManage.experience.address')
                      }})</span
                    >
                    <span class="mr-5">{{
                      webReqEditFacility.address1
                        ? webReqEditFacility.address1
                        : ''
                    }}</span>
                  </div>
                  <div class="boder_gray_padding d-flex justify-space-between">
                    <span
                      >({{ $t('webPageManage.experience.BuildingName') }}・{{
                        $t('webPageManage.experience.CondominiumName')
                      }})</span
                    >
                    <span class="mr-5">{{
                      webReqEditFacility.address2
                        ? webReqEditFacility.address2
                        : ''
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div style="margin: auto; font-size: 10px">
                  <span>{{
                    $t('webPageManage.experience.ContactInformation')
                  }}</span>
                </div>
                <div style="width: 560px">
                  <div class="boder_gray_padding d-flex justify-space-between">
                    <span>{{
                      $t('webPageManage.experience.HomePhoneNumber')
                    }}</span>
                    <span class="mr-5">{{
                      webReqEditFacility.tel ? webReqEditFacility.tel : ''
                    }}</span>
                  </div>
                  <div class="boder_gray_padding d-flex justify-space-between">
                    <span>{{
                      $t('webPageManage.experience.cellPhoneNumber')
                    }}</span>
                    <span class="mr-5">{{
                      webReqEditFacility.telMobile
                        ? webReqEditFacility.telMobile
                        : ''
                    }}</span>
                  </div>
                </div>
              </div>
              <div>
                <div class="boder_gray_padding d-flex justify-space-between">
                  <span>メールアドレス</span>
                  <span class="mr-5">{{
                    webReqEditFacility.email ? webReqEditFacility.email : ''
                  }}</span>
                </div>
              </div>
              <div class="mt-5 mx-4">
                <span style="font-size: 10px" class="blod">{{
                  $t('webPageManage.experience.remarks')
                }}</span>
                <v-textarea
                  outlined
                  v-model="remarks"
                  rows="20"
                  height="200"
                  :rules="[$rules.checkTextAreaLength()]"
                ></v-textarea>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="6">
          <div class="d-flex flex-column h-100">
            <div class="h-100">
              <v-card class="pa-5">
                <h1 style="font-weight: normal; font-size: 25px">
                  {{ $t('webPageManage.experienceDetail.title2') }}
                </h1>
                <v-divider class="my-2"></v-divider>
                <v-row>
                  <v-col :cols="6" class="text-left">
                    <span style="color: #757575">
                      {{ $t('webPageManage.experienceDetail.firstHope') }}
                    </span>
                  </v-col>
                  <v-col :cols="2" class="text-right">
                    <span>
                      {{
                        webReqEditFacility.firstDate
                          ? formatTime(webReqEditFacility.firstDate)
                          : ''
                      }}
                    </span>
                  </v-col>
                  <v-col :cols="1" class="text-right">
                    <span v-if="webReqEditFacility.firstStayDays">
                      {{
                        webReqEditFacility.firstStayDays
                      }}泊
                    </span>
                    <span v-else>
                      
                    </span>
                  </v-col>
                  <v-col :cols="2" class="text-right">
                    <span v-if="webReqEditFacility.firstFacility">
                      {{
                        webReqEditFacility.firstFacility.name
                          ? webReqEditFacility.firstFacility.name
                          : ''
                      }}
                    </span>
                  </v-col>
                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row>
                  <v-col :cols="6" class="text-left">
                    <span style="color: #757575">
                      {{ $t('webPageManage.experienceDetail.secondHope') }}
                    </span>
                  </v-col>
                  <v-col :cols="2" class="text-right">
                    <span
                      >{{
                        webReqEditFacility.secondDate
                          ? formatTime(webReqEditFacility.secondDate)
                          : ''
                      }}
                    </span>
                  </v-col>
                  <v-col :cols="1" class="text-right">
                    <span v-if="webReqEditFacility.secondStayDays">
                      {{
                        webReqEditFacility.secondStayDays
                      }}泊
                    </span>
                    <span v-else></span>
                  </v-col>
                  <v-col :cols="2" class="text-right">
                    <span v-if="webReqEditFacility.secondFacility">
                      {{
                        webReqEditFacility.secondFacility.name
                          ? webReqEditFacility.secondFacility.name
                          : ''
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <v-card class="d-flex justify-end mt-6" flat>
              <v-btn large class="t-btn--red-dark" @click="closeDialog">
                <v-icon class="mr-2">mdi-close</v-icon>
                <span style="font-size:14px">{{
                  $t('webPageManage.experience.close')
                }}</span>
              </v-btn>
              <v-btn large class="t-btn--prm ml-3" @click="saveFac(webReqEditFacility.id)">
                <span style="font-size:14px">{{
                  $t('webPageManage.experience.save')
                }}</span>
              </v-btn>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <connect-existing-customer
        :visible="visible === 2"
        @close="visible = 1"
      />
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ConnectExistingCustomer from '../ConnectExistingCustomer.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import moment from 'moment'
export default {
  components: { ConnectExistingCustomer },
  name: 'FacilityListDetail',
  data() {
    return {
      remarks: '',
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dataGroup: [],
      visibleCompChirld: false,
    };
  },
  props: {
    visible: Boolean,
  },
  watch: {
    webReqEditFacility() {
      this.remarks = this.webReqEditFacility.remarks;

    },
  },
  computed: {
    ...mapGetters(['webReqEditFacility']),
    status: {
      get() {
        if (this.webReqEditFacility.handledAt) {
          this.setSatusOC('Handled');
          return { id: 'Handled', name: '対応済' };
        } else {
          this.setSatusOC('New');
          return { id: 'New', name: '未対応' };
        }
      },
      set(value) {
        this.setSatusOC(value);
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  // moment(){
  //   this.getWebReqEditFacility()
  // },
  methods: {
    ...mapActions(['updateWebrequestOC']),
    ...mapMutations(['setSatusOC', 'setRemarksOC']),
    saveFac(id) {
      if (this.$refs.form.validate()) {
        this.setRemarksOC(this.remarks);
        this.updateWebrequestOC(id).then(() => {
          this.$emit('reset')
          this.closeDialog()
        });
      }
    },
    closeDialog() {
      this.$emit('close');
    },
    formatDate(date){
      return moment(date).format('YYYY/M/DD HH:mm');
    },
    formatTime(date){
      return moment(date).format('MM/YY')
    }
  },
};
</script>

<style lang="scss" scoped>
.boder_gray_padding {
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 500;
  color: #000;
  font-size: 10px;
}
h1 {
  color: #333333;
  font-weight: 400 !important;
}
h2 {
  font-size: 20px;
  color: #000;
  font-weight: bold;
}
h4 {
  font-size: 17px;
  font-weight: 400;
  color: #616161;
}
.h-100 {
  height: 100%;
}
.v-btn {
  font-size: 12px;
  height: 32px !important;
  padding: 10px 8px !important;
}
.div-alert {
  background-color: #0b6786;
  border-radius: 10px;
  color: white;
  width: 450px;
  height: 46px;
  line-height: 46px;
  padding: 0 15px;
}

::v-deep {
  .v-textarea {
    textarea {
      font-size: 12x !important;
      color: #000000 !important;
    }
  }
  .btn-customer-details {
    background-color: #757575 !important;
    font-size: 12px;
    padding: 2px;
    color: #fafafa;
  }
  .icon-alert {
    font-size: 16px;
    color: #666666;
  }
  .btn-div-alert {
    background-color: #0b6786 !important;
    color: white;
    border: 1px solid white;
    font-size: 14px !important;
    min-width: 150px;
  }
  .v-dialog {
    width: 1500px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
  }
  .v-select__selection--comma {
    font-size: 16px !important;
    margin: 0 !important;
    color: #666666 !important;
  }
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 39px;
    height: 39px;
  }
  .w-50 {
    width: 37%;
  }
  .mr-5 {
    margin-right: 20px !important;
  }
  .v-text-field--enclosed .v-input__append-inner {
    margin: auto !important;
  }
  .v-icon__svg {
    color: #424242;
  }
  .v-text-field--outlined.v-input--is-focused fieldset {
    border: 2px solid #9e9e9e !important;
  }
}
.text-title {
  font-size: 22px !important;
}
.text-time {
  font-size: 17px !important;
}
div.content span {
  font-size: 12px !important;
}
.text-right {
  color: #212121;
  font-weight: bold;
  font-size: 16px;
  text-align: right;
}
.text-left {
  color: #757575;
  font-weight: bold;
  font-size: 16px;
  text-align: right;
}
</style>
